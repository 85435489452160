@import "theme.module.scss";
.custom-input {
  height: 40px !important;
  border-radius: 6px !important;
  padding: 5px 12px;
  border: 1px solid #d9d9d9;
  font-family: "Montserrat-Regular";
  width: 100%;
  .ant-input-number-input {
    padding: 0 !important;
  }
  ::placeholder {
    font-family: "Montserrat-Regular";
    font-size: 14px;
  }
}
.table-search {
  height: 32px !important;
  @media (max-width: 530px) {
    width: 100% !important;
  }
}
.custom-textarea {
  border-radius: 6px;
  padding: 5px 12px;
  border: 1px solid #d9d9d9;
  font-family: "Montserrat-Regular";
  width: 100%;
  ::placeholder {
    font-family: "Montserrat-Regular";
  }
}
.error-input {
  border: 1px solid $errorMain !important;
}
.custom-url {
  height: 40px;
  .ant-input-wrapper {
    height: 40px;
    .ant-select-selector {
      height: 100%;
    }
    .ant-input {
      height: 40px;
    }
  }

  font-family: "Montserrat-Regular";
  ::placeholder {
    font-family: "Montserrat-Regular";
  }
}
.error-url {
  .ant-input-group .ant-input-group-addon {
    border: 1px solid $errorMain !important;
    border-right: none !important;
  }
  .ant-input {
    border-color: $errorMain !important;
  }
}
.custom-label {
  font-family: "Montserrat-Regular";
  // text-align: left;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
}
.custom-button {
  padding: 5px 16px !important;
  width: auto;
  height: 40px !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 6px !important;
  font-family: "Montserrat-Regular" !important;
  transition: border 0s ease-in-out, background-color 0.5s ease-in-out;
  @media (max-width: 830px) {
    width: 100% !important;
  }
}
.custom-card-button {
  height: 179px;
  width: 100%;
  border-radius: 8px;
  background-image: url("../assets/images/lexinvest.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  .card-button-text-wrapper {
    position: absolute;
    right: 20px;
    margin-top: 20px;
    @media (min-width: 2400px) {
      margin-top: 52.5px;
      right: 10%;
    }
    .card-button-icon {
      color: $main;
    }
    .card-button-text {
      color: $grey300;
      font-weight: 400 !important;
      font-family: "Montserrat-Light" !important;
    }
  }
}
.custom-card-button:hover {
  color: $primary !important;
  border-color: $primary !important;
}
.custom-button:disabled {
  background: #f5f5f5 !important;
  border: 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  .anticon {
    svg {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}
.ant-btn:disabled {
  background: #f5f5f5 !important;
  border: 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.status-avatar {
  width: 38.71px;
  height: 38.71px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green-avatar {
  background: #52c41a;
}
.red-avatar {
  background: $errorMain;
}
.primary-button {
  color: #ffff !important;
  background-color: $secondary !important;
  border: 1px solid $secondary !important;
  .anticon {
    svg {
      color: #ffff !important;
    }
  }
}
.main-button {
  color: #ffff !important;
  background-color: $main !important;
  border: 1px solid $main !important;
  .anticon {
    svg {
      color: #ffff !important;
    }
  }
}
.skyblue-button {
  color: #ffff !important;
  background-color: $clearblue !important;
  border: 1px solid $clearblue !important;
  .anticon {
    svg {
      color: #ffff !important;
    }
  }
}
.danger-button {
  color: #ffff !important;
  background-color: $errorMain !important;
  border: 1px solid $errorMain !important;
  .anticon {
    svg {
      color: #ffff !important;
    }
  }
}
.secondary-button {
  color: $main !important;
  background-color: #ffffff !important;
  border: 1px solid $main !important;
  .anticon {
    svg {
      color: $main !important;
    }
  }
}
.PhoneInput {
  width: 100%;
  transition: border 0.1s ease-in;
}
.PhoneInput:hover {
  border-color: #4096ff !important;
  border-inline-end-width: 1px !important;
}
.PhoneInputInput {
  width: 100%;
  height: 40px !important;
  border: 1px solid #d9d9d9;
  transition: border 0.1s ease-in;
  padding-left: 5px;
}
.error-phone {
  border: 1px solid $errorMain !important;
  border-right: none !important;
  .PhoneInputInput {
    border: 1px solid $errorMain !important;
    border-left: none !important;
  }
}
.PhoneInputInput:focus {
  outline: none;
}
.PhoneInputInput:hover {
  border-color: #4096ff !important;
  border-inline-end-width: 1px !important;
}
.PhoneInput--focus {
  border-radius: 6px !important;
  // border-color: red !important;
  border-color: #4096ff !important;
  border-inline-end-width: 1px !important;
  input {
    border-color: #4096ff !important;
    border-inline-end-width: 1px !important;
  }
}
.case-checkbox {
  .ant-checkbox {
    align-self: center;
  }
}
.custom-checkbox {
  // .ant-checkbox {
  //   // align-self: center;
  // }
  .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: white !important;
  }
  span {
    text-align: start;
  }
}

.phone-input {
  input {
    border-radius: 0px 6px 6px 0px !important;
    width: 100%;
    border-left: none !important;
    border-right: none !important;
  }
  input:focus {
    border-left: none !important;
    border-right: none !important;
  }
  input::placeholder {
    color: #d9d9d9;
    opacity: 1;
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1301;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    font-size: 30px !important;
    color: $primary !important;
  }
}

.upload-wrapper {
  width: 100% !important;
  @media (max-width: 830px) {
    margin-top: 10px;
  }
  .ant-upload-select {
    width: 100% !important;
  }
  .ant-btn-default:not(:disabled):hover {
    color: $clearblue;
    border-color: $clearblue;
  }
  .custom-upload {
    height: 40px;
    background: #ffffff;
    border: 1px dashed $secondary;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 6px;
    width: 100% !important;
  }
}
.custom-select {
  .ant-select-selector {
    height: 40px !important;
    border-radius: 6px !important;
    padding: 5px 12px;
    border: 1px solid #d9d9d9;
    font-family: "Montserrat-Regular";
    width: 100%;
  }
}
.custom-multiple-select {
  .ant-select-selector {
    min-height: 40px !important;
    border-radius: 6px;
    padding: 5px 12px;
    border: 1px solid #d9d9d9;
    font-family: "Montserrat-Regular";
    width: 100%;
  }
}
.text-multiple {
  .ant-select-arrow {
    display: none !important;
  }
}
.profile-input {
  width: 125px;
  .ant-select-selector {
    height: 32px !important;
    padding: 0px 12px !important;
    .ant-select-selection-item {
      line-height: 24px !important;
    }
  }
}

.error-select {
  .ant-select-selector {
    border: 1px solid $errorMain !important;
  }
}
.ant-select-selection-item {
  font-family: "Montserrat-Regular" !important;
  text-align: start !important;
  padding-top: 3px !important;
}
.icon-button-filter {
  padding: 5px 9px !important;
  width: auto !important;
  height: 32px !important;
  color: $secondary !important;
  background-color: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  .anticon {
    svg {
      color: $secondary !important;
    }
  }
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 6px;
  transition: border 0s ease-in-out, background-color 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 830px) {
    width: 100% !important;
  }
}
.custom-table {
  width: 100%;
  background-color: #ffffff !important;
  border: 0.5px solid $clearblue !important;
  .ant-select-selection-item {
    line-height: 24px !important;
  }
  .table-header-wrapper {
    flex-wrap: wrap;
    gap: 10px;
    .table-title {
      font-family: "Montserrat-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      flex-grow: 1;
      text-align: start;
    }
    .table-button {
      padding: 5px 16px !important;
      width: auto !important;
      height: 32px !important;
      color: #ffff !important;
      background-color: $main !important;
      border: 1px solid $main !important;
      .anticon {
        svg {
          color: #ffff !important;
        }
      }
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 6px;
      transition: border 0s ease-in-out, background-color 0.5s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 830px) {
        width: 100% !important;
      }
    }
    .table-button-filter {
      padding: 5px 9px !important;
      width: auto !important;
      height: 32px !important;
      color: $secondary !important;
      background-color: #ffffff !important;
      border: 1px solid #d9d9d9 !important;
      .anticon {
        svg {
          color: $secondary !important;
        }
      }
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 6px;
      transition: border 0s ease-in-out, background-color 0.5s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 830px) {
        width: 100% !important;
      }
    }
  }
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  padding: 0px 16px !important;
}
.ant-table-cell {
  font-family: "Montserrat-Regular";
  .table-star {
    // margin-right: 10px;
    color: #bfbfbf;
    font-size: 16px;
    color: $main;
  }
  .table-star-filled {
    // margin-right: 10px;
    color: $main;
    font-size: 16px;
  }
  .space-from-right {
    margin-right: 10px;
  }
  .space-from-left {
    margin-left: 10px;
  }
}

.ant-drawer-content-wrapper {
  @media (max-width: 650px) {
    width: 100% !important;
  }
}
.custom-drawer {
  .drawer-close-icon {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.45);
    :hover {
      color: rgba(0, 0, 0, 0.88);
    }
  }
  .drawer-avatar {
    width: 100px;
    height: 100px;

    position: relative !important;
    .featured {
      background: #ffffff;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
        0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      color: orange;
      position: absolute;
      right: 6px;
      bottom: 0px;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .drawer-button {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 5px 16px !important;
    font-family: "Montserrat-Regular" !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
    border-radius: 6px !important;
  }
  .drawer-button:disabled {
    background: #f5f5f5 !important;
    border: 1px solid #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .view-full-profile-button {
    border: none !important;
    color: #1890ff !important;
    border: none !important;
  }

  .drawer-user-name {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }
  .drawer-user-role {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.45);
  }
  .drawer-user-icons {
    height: 31px;
    width: 31px;
    border-radius: 50%;
    background: #f5f5f5;
    .link-icon {
      width: 100%;
      height: 100%;
      font-size: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #595959;
    }
  }
  .drawer-user-address {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
  .borderless-button {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  }
  .drawer-label {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.45);
  }
  .drawer-user-info {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }
  .drawer-company-logo {
    width: 42px;
    height: 42px;
  }
  .drawer-checks {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $clearblue;
  }
  .space-right {
    margin-right: 5px;
  }
  .space-left {
    margin-left: 5px;
  }
  .drawer-error {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ff4d4f;
  }
}
.accept-button {
  color: #ffff !important;
  background-color: #52c41a !important;
  border: 1px solid #52c41a !important;
  .anticon {
    svg {
      color: #ffff !important;
    }
  }
}
.reject-button {
  color: #ffff !important;
  background-color: #ff4d4f !important;
  border: 1px solid #ff4d4f !important;
  .anticon {
    svg {
      color: #ffff !important;
    }
  }
}
.ant-modal-title {
  font-family: "Montserrat-Regular";
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
.bordered-card {
  border: 0.5px solid $clearblue;
  // background: $secondary;
}
.custom-statistics-card {
  border-radius: 4px !important;
  box-shadow: none !important;
  .statistics-card-title {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    // color: rgba(0, 0, 0, 0.85);
    color: white;
  }
  .statistics-card-percentage {
    color: $main;
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
  .pointer {
    cursor: pointer;
  }
  .statistics-card-label {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    // color: rgba(0, 0, 0, 0.45);
    color: white;
  }
  .statistics-info {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
  }
  .accepted {
    color: #52c41a;
  }
  .rejected {
    color: #ff4d4f;
  }
  .review {
    color: #597ef7;
  }
}
.investor-card {
  min-height: 507.3px;
  max-height: 507.3px;
  overflow: auto;
}
.card-divider {
  border-block-start: 1px solid $main;
}
.card-divider-info {
  border-block-start: 1px solid $clearblue;
}
.card-divider-vertical {
  border-inline-start: 1px solid $main;
}
.secondary-card {
  background: $secondary !important;
}
.custom-dashboard-card {
  border-radius: 4px !important;
  box-shadow: none !important;
  width: 100%;
  .dashboard-card-label {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    // color: rgba(0, 0, 0, 0.45);
    color: white;
  }
  .label {
    color: $main;
  }
  .percentage {
    color: #69c0ff;
  }
  .accepted {
    color: #52c41a !important;
  }
  .total {
    color: $main !important;
  }
  .more-icon {
    cursor: pointer;
    font-size: 14px;
    :hover {
      color: $clearblue;
    }
  }
  .member-info {
    color: rgba(0, 0, 0, 0.25);
  }

  .card-avatar-network {
    width: 72px;
    height: 75px;
    position: relative !important;
    .featured {
      background: #ffffff;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
        0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      color: orange;
      position: absolute;
      right: 0px;
      bottom: -4px;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .card-avatar {
    width: 92px;
    height: 95px;

    position: relative !important;
    .featured {
      background: #ffffff;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
        0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      color: orange;
      position: absolute;
      right: 6px;
      bottom: 0px;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .investment-title {
    color: #000000;
  }
  .investment-subtitle {
    color: #595959;
  }
  .investment-tag {
    background: #ebfaff;
    border: none;
    border-radius: 20px;
    padding: 0 12px;
    color: #023a4b;
  }
  .dashboard-card-title {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #858890;
    // color: white;
    // color: $main;
  }
  .dashboard-card-value {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #0b1221;
  }
  .dashboard-card-second-value {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    // color: #0b1221;
    color: $main;
  }
  .dashboard-card-percentage {
    font-family: "Montserrat-Regular";
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    .rise {
      color: #52c41a !important;
    }
  }
  .rise {
    color: #52c41a;
  }
  .down {
    color: #ff4d4f;
  }
  .chart-label {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #0b1221;
  }
}
.modal-select {
  @media (max-width: 830px) {
    width: 100% !important;
  }
}
.modal-button {
  @media (max-width: 830px) {
    width: 100% !important;
    padding-top: 10px;
  }
}
.ant-select-multiple .ant-select-selection-item {
  padding-top: 0px !important;
}
.ant-select-multiple .ant-select-selection-placeholder {
  inset-inline-end: inherit !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.case-headline-button {
  @media (max-width: 750px) {
    width: 100% !important;
  }
}
.case-card-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $main !important;
  opacity: 0.5;
}
.case-card-steps {
  padding: 40px 0px 0px 0px;

  .first-element {
    transition: all 0.2s ease-in-out;
    .ant-steps-item-content {
      @media (min-width: 750px) {
        width: 75px !important;
        display: flex;
        margin-top: 0px;
      }
    }
    .ant-steps-item-icon {
      position: relative;
      height: 15px !important;
      width: 15px !important;
      top: -4px !important;
      @media (max-width: 650px) {
        inset-inline-start: -3px !important;
      }
    }
  }
  .ant-steps-item-icon {
    transition: all 0.2s ease-in-out;
    position: relative;
  }
  .ant-steps-item-wait:not(.first-element).ant-steps-item:hover {
    transition: all 0.2s ease-in-out;
    .ant-steps-item-icon {
      transition: all 0.2s ease-in-out;
      .ant-steps-icon {
        transition: all 0.2s ease-in-out;
      }
      position: relative;
      height: 15px !important;
      width: 15px !important;
      margin-top: -4px !important;
      margin-right: 3px !important;
      @media (max-width: 650px) {
        inset-inline-start: -3px !important;
      }
    }
  }
  .ant-steps-item-finish:not(.first-element).ant-steps-item:hover {
    transition: all 0.2s ease-in-out;
    .ant-steps-item-icon {
      transition: all 0.2s ease-in-out;
      .ant-steps-icon {
        transition: all 0.2s ease-in-out;
      }
      position: relative;
      height: 15px !important;
      width: 15px !important;
      margin-top: -4px !important;
      margin-right: 3px !important;
      @media (max-width: 650px) {
        inset-inline-start: -3px !important;
      }
    }
  }
  .ant-steps-item-tail::after {
    @media (min-width: 750px) {
      width: calc(100% - 40px) !important;
    }
  }
  .ant-steps-item-tail {
    @media (min-width: 750px) {
      margin-inline-start: 12px !important;
    }
  }
  .ant-steps-item-content {
    @media (min-width: 750px) {
      width: 30px !important;
    }
  }
  .ant-steps-item-process {
    .step-title {
      display: block !important;
      text-align: start;
      word-break: keep-all;
    }
    .ant-steps-item-content {
      @media (min-width: 750px) {
        width: 75px !important;
        display: flex;
        margin-top: 0px;
      }
    }
  }
  .ant-steps-item-process .ant-steps-item-icon {
    height: 15px !important;
    width: 15px !important;
    top: -4px !important;
    .ant-steps-icon .ant-steps-icon-dot {
      background: $main;
    }
    @media (max-width: 650px) {
      inset-inline-start: -3px !important;
    }
    @media (min-width: 750px) {
      margin-inline-start: 0px !important;
    }
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    @media (min-width: 750px) {
      margin-inline-start: 0px !important;
    }
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    .ant-steps-icon .ant-steps-icon-dot {
      background: $main;
    }
    @media (min-width: 750px) {
      margin-inline-start: 0px !important;
    }
  }
  .ant-steps-item .ant-steps-item-wait {
    .ant-steps-item-tail {
      @media (min-width: 750px) {
        margin-inline-start: 12px !important;
      }
    }
    .ant-steps-item-content {
      @media (min-width: 750px) {
        width: 30px !important;
      }
    }
  }
}
.case-card-steps.ant-steps.ant-steps-label-vertical.ant-steps-small:not(
    .ant-steps-dot
  )
  .ant-steps-item-icon {
  @media (min-width: 750px) {
    margin-inline-start: 0px !important;
  }
}

.investor-bids-button {
  color: black;
}
.button-accept:hover {
  color: #1890ff !important;
}
.button-decline:hover {
  color: #ff4d4f !important;
}

#published-chart {
  height: 100% !important;
  width: 100% !important;
}
.apexchartspublished-chart {
  height: 100% !important;
  width: 100% !important;
}
.apexcharts-svg {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.user-row {
  cursor: pointer;
  :hover {
    color: $clearblue !important;
  }
  .id-text {
    color: gray;
  }
}
.big-card {
  width: 70px;
  height: 70px;
}
.small-card {
  width: 45px;
  height: 45px;
  @media (max-width: 650px) {
    width: 70px;
    height: 70px;
  }
}
.small-screen-avatar {
  display: none;
  @media (max-width: 820px) {
    display: block;
  }
}
.large-screen-avatar {
  @media (max-width: 821px) {
    display: none;
  }
}
.investment-avatar {
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
  }
}
.primary-card {
  background-color: $primary;
  border: 1px solid $main;
  border-radius: 0px !important;
  padding: 24px 40px;
  width: 100% !important;
}
.custom-lexhub-card {
  border-radius: 0px !important;
  .lexbrand-title-wrapper {
    margin-top: 32px;
  }
  .lexbrand-logo-wrapper {
    margin-top: 50px;
    height: 37.1px;
    .lexbrand-logo {
      height: 37.1px;
    }
  }
  .lexbrand-button-wrapper {
    margin-top: 40px;
  }

  .lexhub-member-bio {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    height: 150.9px;
  }
  .ant-card-body {
    padding: 0 !important;
    height: 100%;
  }
  .divider {
    width: 50px;
    height: 3px;
    background-color: $main;
  }
  .lexhub-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: 830px) {
      width: 100% !important;
      height: 350px;
    }
  }
  .lexhub-card-button {
    width: 70%;
    border-radius: 0px !important;
    font-size: 20px;
    height: 42px !important;
    span {
      font-family: "Montserrat-SemiBold";
    }
    @media (max-width: 830px) {
      width: 100% !important;
    }
  }
  .lexhub-card-button:hover {
    border-color: #cef0ea !important;
    color: black !important;
    background-color: #cef0ea !important;
  }
}
.custom-lexvideo-card {
  background-color: rgba(12, 18, 37, 0.89);
  width: 100%;
  padding: 10px;
  .lexinvest-logo {
    opacity: 1 !important;
    @media (max-width: 830px) {
      width: 270px;
    }
  }
  .lexbrand-button-wrapper {
    margin-top: 40px;
    .lexinvest-card-button {
      width: 200px;
      border-radius: 0px !important;
      font-size: 20px;
      height: 42px !important;
      span {
        font-family: "Montserrat-SemiBold";
      }
      @media (max-width: 830px) {
        width: 100% !important;
      }
    }
    .lexinvest-card-button:hover {
      border-color: #cef0ea !important;
      color: black !important;
      background-color: #cef0ea !important;
    }
  }
}
.investor-stats-card {
  .ant-card-body {
    padding: 22px !important;
  }
}
.title-button-wrapper {
  justify-content: space-between;
  @media (max-width: 830px) {
    flex-direction: column;
    justify-content: start !important;
    align-items: start !important;
  }
  .management-link {
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
  .management-link:hover {
    color: $main;
  }
}
.mobile-divider {
  display: none;
  @media (max-width: 830px) {
    display: block;
  }
}
.demo-video {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
