@import "theme.module.scss";

.sider {
  // text-align: center !important;
  line-height: 120px !important;
  color: #fff !important;
  background: linear-gradient(
    136.1deg,
    #00212b -19.11%,
    $secondary 90.23%
  ) !important;
  width: 40% !important;
  min-width: 40% !important;
  max-width: 40% !important;
  position: fixed !important;
  height: 100%;

  @media (max-width: 830px) {
    display: none;
  }

  .ant-layout-sider-children {
    background-image: url("../assets/images/Circles.png") !important;
    background-repeat: no-repeat !important;
    // background-position: bottom left !important;
    background-position-x: 165px;
    background-position-y: bottom;
    background-size: contain;
  }

  .sider-right-gap {
    padding-right: 69px;
  }

  .sider-left-gap {
    padding-left: 69px;
  }

  .sider-content {
    padding-top: 130px;
    height: 100%;

    .auth-logo {
      width: 162px;
      height: 50px;
    }

    .auth-title {
      font-family: "Montserrat-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 135.02%;
      width: 320px;
      height: 122px;
      color: #ffffff !important;
      margin-top: 45px !important;
    }

    .auth-footer {
      position: absolute;
      font-family: "Montserrat-Regular";
      bottom: 59px;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #ffffff;
    }
  }
}

.content-layout {
  width: 60% !important;

  .auth-header {
    background: linear-gradient(
      136.1deg,
      #00212b -19.11%,
      $secondary 90.23%
    ) !important;
    padding-inline: 13px !important;

    .header-content {
      display: flex;
      align-items: center;
      height: 100%;
      background-image: url("../assets/images/Circles.png") !important;
      background-repeat: no-repeat !important;
      background-size: contain;
      background-position: right;

      .auth-logo {
        height: 50px;
        width: auto;
      }
    }

    display: none;

    @media (max-width: 830px) {
      display: block;
    }
  }

  .content {
    text-align: center !important;
    min-height: 100vh !important;
    line-height: 120px !important;
    color: black !important;
    background-color: $paper !important;
    // padding-left: 40%;
    // @media (max-width: 830px) {
    //   padding-left: 0%;
    // }
  }

  .right-gap {
    padding-right: 40%;

    @media (max-width: 830px) {
      padding-right: 0%;
    }
  }

  .left-gap {
    padding-left: 40%;

    @media (max-width: 830px) {
      padding-left: 0%;
    }
  }
}
// .globe-login-content {
// height: 100% !important;
// }
.login-content {
  padding-top: 192px;
  padding-left: 72px;
  padding-right: 72px;
  // height: 100% !important;

  @media (max-width: 830px) {
    padding-top: 90px;
    padding-left: 30px;
    padding-right: 30px;
  }
  //jom kon tu test se sun i gjejsha styles edhe parentat e sene
  .login-title {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    text-align: start;
  }
}
//boni revert qito changes se qikjo login content perdoret tjeter kun ed

.register-content {
  padding: 101px 72px 50px 72px;

  // padding-left: 72px;
  // padding-right: 72px;
  // padding-bottom: 50px;
  @media (max-width: 830px) {
    padding: 101px 30px 50px 30px;
  }

  .step-section {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: "Montserrat-Regular";
    line-height: 20px;
    color: #000000;
    padding-bottom: 30px;
  }

  .register-title {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }

  .member-card:hover {
    border: 1px solid #82c6db;
  }

  .member-card {
    width: 100%;
    height: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    transition: border 0.2s ease-in-out, background-color 0.5s ease-in-out;
    cursor: pointer;

    .select {
      padding: 14px 14px 0px 14px;
    }

    .icon {
      height: 40px;
      width: 40px;
      border: 1px solid #c1e7f2;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      font-family: "Montserrat-SemiBold";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
      padding-top: 30px;
    }

    .text {
      font-family: "Montserrat-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #000000;
      padding-top: 14px;
      padding-bottom: 37px;
    }

    .members-group {
      height: 100% !important;
      width: 100% !important;
    }
  }

  .checked-member {
    border: 1px solid #82c6db !important;
    background-color: #f3fcff !important;
  }

  .checkbox-option {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  .information {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: end;
    color: #8c8c8c;
  }

  .final-step {
    padding-top: 200px;

    .title {
      padding-bottom: 36px;
      z-index: 1;
    }

    .confetti {
      position: absolute;
      top: 230px;
      width: 244px;
      height: 189px;
      z-index: 0;
    }

    .text {
      font-family: "Montserrat-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      text-align: start;
      color: #000000;
      padding-top: 27px;
      z-index: 1;

      .link {
        text-decoration: underline;
        color: #000000;
      }
    }
  }
}

.ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  background-color: #82c6db;
  border-color: #82c6db;
}

.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #82c6db;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: $secondary !important;
  border-color: $secondary !important;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $secondary !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-checked::after {
  border-color: $secondary !important;
}

.ant-checkbox-checked::after {
  border: 2px solid $secondary !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $secondary !important;
}
.login-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.login-logo {
  width: 300px;
  position: absolute;
  left: 25%;
  top: 5%;
  transform: translateX(-30%);
}
.login-text {
  font-family: "Montserrat-Light" !important;
  position: absolute !important;
  bottom: 5% !important;
  text-align: center !important;
  color: #ffffff !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: 0 !important;
  right: 0 !important;
}
.login-button {
  background-color: #b89e4f;
  border: none;
  width: 100% !important;
}
.login-button:hover {
  color: black !important;
  background-color: #cef0ea !important;
}
.login-text-button {
  border: none !important;
  color: white !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.login-text-button:hover {
  color: $main !important;
}
