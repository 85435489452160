// color variants
@import "theme.module.scss";
@import "./fonts.scss";

body {
  margin: 0;
  font-family: "Montserrat-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat-Regular";
}

.ant-notification-notice-message {
  display: none !important;
}
.ant-notification .ant-notification-notice {
  width: 432px !important;
  padding: 23px 10px !important;
}
.ant-notification .ant-notification-notice .ant-notification-notice-icon {
  font-size: 14px !important;
}
.ant-notification
  .ant-notification-notice
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-inline-start: 21px !important;
}
.ant-notification-notice-close {
  display: none !important;
}
.ant-notification-notice-with-icon {
  display: flex !important;
  align-items: center !important;
}
.ant-notification-notice-success {
  border-left: 1px solid #52c41a;
}
.ant-notification-notice-error {
  border-left: 1px solid #ff4d4f;
}
.ant-notification-notice-warning {
  border-left: 1px solid #faad14;
}
.ant-notification-notice-info {
  border-left: 1px solid #1677ff;
}
.ant-notification-notice-description {
  font-family: "Montserrat-Regular" !important;
}
div {
  font-family: "Montserrat-Regular" !important;
  font-style: normal;
}
h1 h2 h3 h4 h5 h6 {
  font-family: "Montserrat-Regular" !important;
  font-style: normal;
}
p {
  font-family: "Montserrat-Regular" !important;
  font-style: normal;
}
span {
  font-family: "Montserrat-Regular" !important;
  font-style: normal;
}
.semi-bold {
  font-family: "Montserrat-SemiBold" !important;
}
.bold {
  font-family: "Montserrat-Bold" !important;
}
.light {
  font-family: "Montserrat-Light" !important;
}
.f-10 {
  font-size: 10px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-22 {
  font-size: 22px !important;
}
.f-24 {
  font-size: 24px !important;
}
.f-26 {
  font-size: 26px !important;
}
.f-28 {
  font-size: 28px !important;
}
.f-30 {
  font-size: 30px !important;
}
.f-400 {
  font-weight: 400 !important;
}
.f-500 {
  font-weight: 500 !important;
}
.f-600 {
  font-weight: 600 !important;
}
.f-700 {
  font-weight: 700 !important;
}
.l-14 {
  line-height: 14px !important;
}
.l-16 {
  line-height: 16px !important;
}
.l-18 {
  line-height: 18px !important;
}
.l-20 {
  line-height: 20px !important;
}
.l-22 {
  line-height: 22px !important;
}
.l-24 {
  line-height: 24px !important;
}
.l-26 {
  line-height: 26px !important;
}
.l-28 {
  line-height: 28px !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.primary {
  color: $primary !important;
}
.secondary {
  color: $secondary !important;
}
.main {
  color: $main !important;
}
.clearblue {
  color: $clearblue !important;
}
.error {
  color: $errorMain !important;
}
.white {
  color: $paper !important;
}
.dark {
  color: $dark !important;
}
.success {
  color: #52c41a !important;
}
.description {
  color: rgba(0, 0, 0, 0.45) !important;
}

.shaded {
  opacity: 0.5;
}

.ant-skeleton-input {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
}
.ant-skeleton.ant-skeleton-element {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.featured-member-skeleton {
  .ant-skeleton-input {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
  }
  .ant-skeleton.ant-skeleton-element {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
  }
}
.full-width {
  width: 100% !important;
}
.half-width {
  width: 50% !important;
}

.text-left {
  text-align: left !important;
}
.text-start {
  text-align: start !important;
}
.text-end {
  text-align: end !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.flex {
  display: flex !important;
}

.justify-end {
  justify-content: flex-end !important;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-between {
  justify-content: space-between !important;
}
.align-end {
  align-items: flex-end !important;
}
.align-start {
  align-items: flex-start !important;
}
.align-center {
  align-items: center !important;
}
