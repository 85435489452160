@import "theme.module.scss";

.lexhub {
  .lexhub-header {
    border-bottom: 0.5px solid #707070;
    height: 85px;
    width: 100%;
    padding: 0 !important;
    background-color: $primary !important;
    position: fixed;
    z-index: 1000;
  }
  .nav-wrapper {
    height: 100%;
    @media (max-width: 831px) {
      display: none;
    }
  }
  .nav-main-item {
    color: white;
    span {
      font-family: "Montserrat-Bold" !important;
    }
  }
  .nav-main-item:hover {
    color: $main !important;
  }
  .nav-item {
    span {
      font-family: "Montserrat-Light" !important;
    }
    color: #ffffff;
  }
  .nav-item:hover {
    color: $main !important;
  }

  .sm-nav-wrapper {
    display: none;
    height: 100%;
    @media (max-width: 830px) {
      display: flex;
    }
    .nav-button {
      color: #ffffff;
    }
    .nav-button:hover {
      color: $main !important;
    }

    .show {
      display: block;
      animation: animation-open-menu 0.5s;
      animation-fill-mode: forwards;
    }
    .hide {
      display: none;
      animation: animation-close-menu 0.5s;
      animation-fill-mode: forwards;
    }
  }
  .sm-menu {
    position: fixed;
    top: 85px;
    height: 100%;
    width: 100%;
    background-color: $primary;
    z-index: 10000;
    left: 0;
    width: 100%;
    overflow: hidden;
    padding-top: 30px;
  }
  .menu-show {
    display: flex !important;
    animation: animation-open-menu 0.5s;
    animation-fill-mode: forwards;
  }
  .menu-hide {
    display: none !important;
    animation: animation-close-menu 0.5s;
    animation-fill-mode: forwards;
  }
  .lexhub-content {
    // margin-top: 85px;
    .banner-wrapper {
      padding: 145px 30px 60px 30px !important;
      background-color: $primary;
      transition: all 0.2s ease-in-out;
      .lexhub-logo {
        @media (max-width: 830px) {
          width: 100%;
        }
      }
      .coming-soon-logo {
        width: 539px;
        height: 155px;
        object-fit: contain;
        @media (max-width: 830px) {
          width: 100%;
        }
      }
      .show {
        display: block;
        animation: animation-open-menu 0.75s;
        animation-fill-mode: forwards;
      }
      .hide {
        display: none;
        animation: animation-close-menu 0.75s;
        animation-fill-mode: forwards;
      }
    }
    .profile-section {
      padding: 90px 200px;
      margin-left: 0px !important;
      margin-right: 0px !important;
      @media (max-width: 830px) {
        padding: 60px 30px;
      }
      .profile-picture {
        width: 150px !important;
        height: 140px !important;
      }
      .container {
        // border: 3px solid blue;
        // padding: 20px 40px 40px;
        // max-width: 600px;
        &__image {
          width: 150px;
          height: 140px;
          border-radius: 50%;
          margin: 0px 10px 0px 0px;
          float: left;
          object-fit: cover;
        }
        &__text {
          display: inline;
          &__title {
            padding-top: 15px;
          }
          &__bio {
            word-break: keep-all;
            text-align: justify;
            padding-top: 15px;
          }
        }
      }
    }
    .dashboard-card-button {
      height: 100%;
      padding-right: 60px;
      padding-left: 60px;
      @media (max-width: 830px) {
        padding-right: 0px;
        padding-left: 0px;
        margin-top: 30px;
      }
      .button-card-wrapper {
        background-color: $primary;
        padding: 60px;

        .card-element {
          padding-top: 5px;
          padding-bottom: 5px;
          .card-element-button {
            border-radius: 0px !important;
            width: 80%;
            height: 54px !important;
            font-size: 20px;
            span {
              font-family: "Montserrat-SemiBold" !important;
            }
          }
          .card-element-button:hover {
            color: black !important;
            background-color: #cef0ea !important;
          }
        }
      }
    }
    .lexhub-members-section {
      margin-top: 90px;
      margin-bottom: 90px;
    }
    .lexbrand {
      background-color: $primary;
      padding: 90px 200px;
      margin-left: 0px !important;
      margin-right: 0px !important;
      @media (max-width: 830px) {
        padding: 60px 30px;
      }
    }
    .video-wrapper {
      height: 100vh;
      position: relative;
      .lex-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // z-index: -1;
      }
    }
  }
  .lexhub-footer {
    // height: 00px;
    width: 100%;
    padding: 60px 50px;
    background-color: $primary;
    .light {
      font-family: "Montserrat-Light" !important;
      color: #ffffff;
    }
    .semi-bold {
      font-family: "Montserrat-SemiBold" !important;
      color: #ffffff;
    }
  }
}
@keyframes animation-open-menu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes animation-close-menu {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
