// @import "~antd/dist/antd.css";
@import "theme.module.scss";

@font-face {
  font-family: "Montserrat-Black";
  font-style: normal;
  src: url(../assets/font/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  font-style: normal;
  src: url(../assets/font/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  font-style: normal;
  src: url(../assets/font/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  font-style: normal;
  src: url(../assets/font/Montserrat-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  font-style: normal;
  src: url(../assets/font/Montserrat-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  src: url(../assets/font/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  src: url(../assets/font/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  src: url(../assets/font/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Thin";
  font-style: normal;
  src: url(../assets/font/Montserrat-Thin.ttf) format("truetype");
}
