@import "theme.module.scss";
.app-bar {
  //   text-align: center;
  color: #fff;
  height: 60px;
  padding-inline: 20px;
  line-height: 64px;
  background-color: $secondary;
  // border-bottom: 1px solid white;
  position: fixed;
  width: 100%;
  z-index: 100;
  .logo {
    width: 139.8px;
    height: 18.72px;
    cursor: pointer;
  }

  .profile-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .avatar {
      height: 32px;
      width: 32px;
    }
    .profile-name {
      font-family: "Montserrat-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.85);
      padding-bottom: 3px;
    }
    .profile-drop {
      width: 12px;
      height: 10px;
      cursor: pointer;
      svg {
        width: 12px;
        height: 10px;
      }
    }
  }
}
.main-sider-content {
  .main-sider {
    text-align: center;
    line-height: 120px;
    color: #fff;
    // background-color: #ffffff;
    background-color: $secondary !important;
    position: fixed;
    height: 100%;
    z-index: 1;
    margin-top: 60px;
    .menu {
      color: rgba(0, 0, 0, 0.85);
      background-color: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      height: 100%;
      font-family: "Montserrat-Regular" !important;
      margin-bottom: 60px !important;
      overflow: auto;
      scrollbar-color: $main $secondary;
      .ant-menu-submenu-open {
        margin-bottom: 60px !important;
      }
      .ant-menu-sub:last-child {
        margin-bottom: 40px !important;
      }
      .ant-menu-sub {
        // background-color: #ffffff;
        background-color: $secondary !important;
      }
    }
    .collapse-icon {
      position: absolute;
      bottom: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0px 24px;
      height: 40px;
      // background: #ffffff;
      background-color: $secondary !important;
      box-shadow: inset 0px 1px 0px #f0f0f0;
      z-index: 1000000;
      width: 100%;
      color: white;
    }
  }
  .main-content {
    text-align: center;
    min-height: 120px;
    line-height: 120px;
    color: #fff;
    background-color: #f2f5f9;
    transition: margin 0.2s ease-in-out;
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    padding: 30px 30px 70px 30px;
    .main-title {
      font-family: "Montserrat-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.85);
      text-align: start;
    }
    .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
      width: 99px;
    }
    .ant-steps.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot)
      .ant-steps-item-icon {
      margin-inline-start: 38px;
    }
  }
  .ant-layout-footer {
    padding: 20px 15px !important;
  }
  .border-left {
    border-left: 1px solid white;
  }
  .border-right {
    border-right: 1px solid white;
  }
  .main-footer {
    position: fixed;
    bottom: 0;
    // width: 100%;
    height: 40px;
    background-color: $secondary;
    transition: margin 0.2s ease-in-out, width 0.2s ease-in-out;
    .link-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;
      .footer-element {
        font-family: "Montserrat-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: white;
      }
    }
  }
}
.collapsed-sidebar {
  margin-left: 80px;
}
.full-width-sidebar {
  margin-left: 200px;
  @media (max-width: 830px) {
    margin-left: 80px;
  }
}
.collapsed-right-sidebar {
  margin-right: 80px;
}
.full-width-right-sidebar {
  margin-right: 200px;
  @media (max-width: 830px) {
    margin-right: 80px;
  }
}
.collapsed-footer-right-sidebar {
  margin-right: 40px;
}
.full-width-footer-right-sidebar {
  margin-right: 100px;
  @media (max-width: 830px) {
    margin-right: 40px;
  }
}
.footer-c-width {
  width: calc(100vw - 80px);
}
.footer-width {
  width: calc(100vw - 200px);
  @media (max-width: 830px) {
    width: calc(100vw - 80px);
  }
}
.ant-menu-inline .ant-menu-item {
  margin-inline: 0px;
  margin-block: 0px;
  width: 100%;
}
.ant-menu-title-content {
  font-family: "Montserrat-Regular" !important;
  text-align: start !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item {
  gap: 10px !important;
}
.ant-menu-item-disabled {
  .ant-menu-title-content {
    color: white !important;
  }
  .ant-menu-item-icon {
    color: white !important;
  }
}
.ant-menu-dark .ant-menu-item-selected {
  background-color: $secondary !important;
  color: $main !important;
  border-radius: 4px !important;
  border-right: 2px solid $main !important;
}
.ant-menu-item-active {
  // background-color: #ffffff !important;
  // color: rgba(0, 0, 0, 0.85) !important;

  color: white !important;
  background-color: $secondary !important;
  border-radius: 4px !important;
  border-right: 2px solid white !important;
}
.ant-menu-submenu-title:hover {
  background-color: $secondary !important;
  color: white !important;
  // background-color: #ffffff !important;
  // color: rgba(0, 0, 0, 0.85) !important;
  border-radius: 4px !important;
  border-right: 2px solid $main !important;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  margin-inline: 0px !important;
  margin-block: 0px !important;
  width: 100%;
}
.ant-submenu-active {
  // background-color: #ffffff !important;
  // color: rgba(0, 0, 0, 0.85) !important;

  background-color: $secondary !important;
  color: white !important;
}
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-dark
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: white !important;
}
// .ant-menu-submenu-title:hover {
//   border-radius: 4px !important;
//   border-right: 2px solid rgba(0, 0, 0, 0.85) !important;
// }
.ant-menu-submenu-title {
  .ant-menu-title-content {
    margin-inline-start: 21px !important;
  }
}
.ant-menu-dark,
.ant-menu-dark > .ant-menu {
  // background-color: #ffffff !important;

  background-color: $secondary !important;
  color: white !important;
  // color: rgba(0, 0, 0, 0.85) !important;
}
.logout-button {
  font-family: "Montserrat-Regular";
  padding: 0 !important;
  margin: 0 !important;
  color: black !important;
  height: auto !important;
  :hover {
    color: black !important;
  }
}

.custom-float-button {
  right: 30px !important;
  .ant-float-btn-icon {
    width: 100% !important;
  }
}
.settings-section {
  width: 100px;
  .settings-button {
    font-family: "Montserrat-Regular";
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
    color: black;
    border-start-end-radius: 6px !important;
    border-end-end-radius: 6px !important;
    border-start-start-radius: 6px !important;
    border-end-start-radius: 6px !important;
    :hover {
      color: #0086af !important;
    }
  }
  .active {
    background: #d2f1fb;
    border: 1px solid #b4dce8;
    color: #0086af !important;
  }
}
.settings-button:not(:disabled):hover {
  color: #0086af !important;
}
